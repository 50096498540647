import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import CommonButtonDefault from '../common/buttons/default';
import { strings } from '../../localization/strings';

type StepProps = {
  id: number;
  name: string;
  href: string;
};

type ComponentProps = {
  steps: StepProps[];
  currentStep: number;
  onSelectStep: (step: number) => void;
};

export default function Breadcrumbs({ steps, currentStep, onSelectStep }: ComponentProps) {
  return (
    <nav className="w-full border-b border-gray-200 bg-white mb-6 px-3" aria-label="Breadcrumb">
      <ol className="max-w-7xl mx-auto sm:px-6 lg:px-8 flex py-5 space-x-4 justify-start">
        {steps.map((step, index) => (
          <li
            key={step.name}
            className={`flex items-center ${step.id !== currentStep && step.id > currentStep ? 'hidden' : ''}`}
          >
            {index > 0 && <FontAwesomeIcon icon={faAngleRight} className="text-gray-400 text-sm mr-4" />}
            <a
              href={step.href}
              onClick={() => onSelectStep(index + 1)}
              className={`no-underline text-sm font-medium  hover:text-gray-700 ${
                step.id === currentStep ? 'text-gray-900' : 'text-gray-500'
              }`}
              aria-current={step.id === currentStep ? 'page' : undefined}
            >
              {step.name}
            </a>
          </li>
        ))}
      </ol>
    </nav>
  );
}
