import React, { createContext, useMemo } from 'react';

import { setDomain, setLocale } from '../localization/strings';

interface ComponentProps {
  children: React.ReactNode;
}

interface ContextValue {
  locale: string;
}

const SiteContext = createContext<ContextValue>({
  locale: '',
});

function SiteContextProvider({ children }: ComponentProps) {
  const host = window.location.hostname;

  let locale = 'lt';

  if (host.includes('allergomedica')) {
    setLocale('lt');
    setDomain('allergomedica');

    if (host.includes('allergomedica.pl') || host.includes('.pl.allergomedica')) {
      locale = 'pl';
      setLocale('pl');
    }

    if (host.includes('allergomedica.lv') || host.includes('.lv.allergomedica')) {
      locale = 'lv';
      setLocale('lv');
    }
  }

  const contextValue = useMemo(() => ({ locale }), [locale]);

  return <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>;
}

export { SiteContext, SiteContextProvider };
