import { strings as lithuanian } from './locale/lt';
import { strings as polish } from './locale/pl';
import { strings as latvian } from './locale/lv';
import { parametersValues } from '../constants/parameters';

let currentLocale = 'lt';
let currentDomain = 'allergomedica';

export const defaultStrings = lithuanian;

export function setLocale(locale: string) {
  currentLocale = locale;
}

export function setDomain(domain: string) {
  currentDomain = domain;
}

export function strings() {
  if (currentLocale === 'pl') {
    return polish;
  }

  if (currentLocale === 'lv') {
    return latvian;
  }

  return lithuanian;
}

export function parameters() {
  return parametersValues[currentDomain][currentLocale];
}
