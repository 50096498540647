import React from 'react';
import { useRoutes, Outlet } from 'react-router-dom';

import LayoutHeadingComponent from '../../components/layout/heading';
import SearchOrderPageComponent from '../orders/search';
import CreateOrderPageComponent from '../orders/create';
import IndexPageComponent from '../index';

export default function Root() {
  const element = useRoutes([
    {
      path: '/',
      element: <IndexPageComponent />,
    },
    {
      path: '/orders/search',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <SearchOrderPageComponent />,
        },
        {
          path: ':orderId/:email',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <SearchOrderPageComponent />,
            },
          ],
        },
      ],
    },
    {
      path: 'orders/create',
      element: <CreateOrderPageComponent />,
    },
  ]);

  return (
    <div>
      <LayoutHeadingComponent />
      {element}
    </div>
  );
}
