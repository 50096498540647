import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BloodSample } from '../../entities/blodSample';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import Breadcrumbs from '../../components/layout/breadcrumbs';
import SearchOrderFormComponent from '../../components/order/searchForm';
import HeaderComponent from '../../components/bloodRegistration/header';
import { Order } from '../../entities/order';
import RegisterBloodForm from '../../components/bloodRegistration/registerBloodForm';
import { strings } from '../../localization/strings';
import * as locationsApi from '../../services/api/locations';
import { Location } from '../../entities/location';
import { getOrder } from '../../services/api/orders';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { SearchOrderForm } from '../../entities/searchOrderForm';
import { createBloodSample } from '../../services/api/bloodSample';
import { getLisData } from '../../services/api/lisData';
import { LisData } from '../../entities/lisData';

function SearchOrderPageComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [locations, setLocations] = useState<Location[]>([]);
  const [lisData, setLisData] = useState<LisData>();
  const [searchError, setSearchError] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [registerError, setRegisterError] = useState('');

  const navigate = useNavigate();

  const { orderId, email } = useParams();

  const steps = [
    { id: 1, name: strings().breadcrumbs.searchPatient, href: '#' },
    { id: 2, name: strings().breadcrumbs.registerSample, href: '#' },
    { id: 3, name: strings().breadcrumbs.preview, href: '#' },
  ];

  // const orderResult: Order = { firstName: 'Name', lastName: 'Pavarde', birthDate: '1987-12-08', id: 55555 };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const res = await locationsApi.getLocationsList();

        setLocations(res);
      } catch (e: any) {
        setErrorMessage(true);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchLisData = async () => {
      try {
        const res = await getLisData();

        if (res) {
          setLisData(res);
        }
      } catch (e: any) {
        setErrorMessage(true);
      }
    };

    fetchLisData();
  }, [setLisData]);

  const onCompleteSearch = async (data: SearchOrderForm) => {
    if (data) {
      setRegisterSuccess(false);
      setSearchError('');
      setIsLoading(true);
      try {
        const res = await getOrder({
          ...data,
        });

        setIsLoading(false);

        if (res) {
          setCurrentStep(2);
          setOrder(res);
        }
      } catch (e) {
        setSearchError((e as Error).message);
        setIsLoading(false);
      }
    }
  };

  const onCompleteBloodForm = (completed: boolean) => {
    if (completed) {
      setCurrentStep(3);
    } else {
      setCurrentStep(2);
    }
  };

  const onCompleteBloodRegistration = async (data: BloodSample) => {
    if (data) {
      setIsLoading(true);
      try {
        const res = await createBloodSample({ ...data });

        if (res) {
          setCurrentStep(1);
          setRegisterSuccess(true);
          setOrder(undefined);
          navigate(`/`);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setRegisterError((e as Error).message);
        throw e;
      }
    }
  };

  const onSelectStep = (step: number) => {
    setCurrentStep(step);

    if (step === 1) {
      setOrder(undefined);
      setRegisterSuccess(false);
    }
  };

  useEffect(() => {
    const completeSearch = async () => {
      try {
        if (orderId != null) {
          await onCompleteSearch({ orderId: parseInt(orderId, 10), email });
        }
      } catch (e: any) {
        setErrorMessage(true);
      }
    };

    completeSearch();
  }, [orderId, email]);

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      <Breadcrumbs steps={steps} currentStep={currentStep} onSelectStep={onSelectStep} />
      <main>
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
          {searchError && (
            <div className="my-7">
              <AlertPrimary type="danger" title={searchError} />
            </div>
          )}
          {errorMessage && (
            <div className="my-7">
              <AlertPrimary type="danger" title="Sistemos klaida" text="Bandykite vėliau" />
            </div>
          )}
          <div className="lg:w-1/2">
            {registerSuccess && (
              <AlertPrimary text={strings().alert.success.title} type="success" marginBottom="mb-4" />
            )}
            {!order && <SearchOrderFormComponent onCompleteSearch={onCompleteSearch} />}
            {isLoading && <CommonAnimatedLoader />}
            {order && lisData && (
              <div className="mt-5">
                <HeaderComponent order={order} />
                <RegisterBloodForm
                  birthDate={order.patientBirthDate}
                  orderId={order.id}
                  locations={locations}
                  lisData={lisData}
                  step={currentStep}
                  onCompleteBloodForm={onCompleteBloodForm}
                  onCompleteBloodRegistration={onCompleteBloodRegistration}
                  registerFormError={registerError}
                />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default SearchOrderPageComponent;
