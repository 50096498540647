import axios from 'axios';

import { parameters } from '../../localization/strings';
import { Order } from '../../entities/order';
import { BloodSample } from '../../entities/blodSample';

export const getOrder = async (paramString?: object): Promise<Order> => {
  try {
    const url = `${parameters().api.baseUrl}/orders/search`;

    const res = await axios.get(url, { headers: {}, params: paramString });

    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const createOrder = async (data: object): Promise<Order> => {
  try {
    const url = `${parameters().api.baseUrl}/partners/create-order`;

    const res = await axios.post(url, data);

    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
