import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { Order } from '../../entities/order';
import * as locationsApi from '../../services/api/locations';
import { Location } from '../../entities/location';
import { createOrder } from '../../services/api/orders';
import CreateOrderFormComponent from '../../components/order/createForm';
import AlertPrimary from '../../components/common/alerts/alert-primary';

function CreateOrderPageComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [registerError, setRegisterError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const res = await locationsApi.getLocationsList();

        setLocations(res);
      } catch (e: any) {
        setErrorMessage(true);
      }
    };

    fetchLocations();
  }, []);

  const onCompleteCreateOrderForm = async (data: Order) => {
    if (data) {
      setIsLoading(true);
      try {
        const res = await createOrder({ ...data });

        navigate(`/orders/search/${res.id}/${res.email}`);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setRegisterError((e as Error).message);
        throw e;
      }
    }
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      <main>
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
          <div className="lg:w-1/2">
            {registerError && (
              <div className="my-7">
                <AlertPrimary type="danger" title={registerError} />
              </div>
            )}
            {isLoading && <CommonAnimatedLoader />}
            <div className="mt-5">
              <CreateOrderFormComponent locations={locations} onCompleteCreateOrderForm={onCompleteCreateOrderForm} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default CreateOrderPageComponent;
