export const strings = {
  error: {
    fieldIsRequired: 'Pole obowiązkowe',
  },
  alert: {
    success: {
      title: 'Operacja zakończona',
    },
    danger: {
      title: 'Nie można znaleźć zamówienia',
    },
    warning: {
      title: 'Dane nie zostały zapisane.',
      text: 'Potwierdź wprowadzone dane.',
    },
  },
  button: {
    login: 'Logowanie',
    close: 'Zamknij',
    edit: 'Edycja',
    delete: 'Usuń',
    next: 'Kontynuuj',
    confirm: 'Potwierdź',
    back: 'Wroć ',
    createOrder: 'Zamów test',
    searchOrder: 'Zarejestruj pobranie ',
  },
  navigation: {
    bloodSampleRegistration: 'Rejestracja próbki krwi',
    phone: '+48791907929',
    haveQuestions: 'Czy masz pytania?',
  },
  breadcrumbs: {
    searchPatient: 'Wyszukiwanie pacjentów',
    registerSample: 'Rejestracja pobranej próbki krwi',
    preview: 'Zobacz na stronie',
  },
  form: {
    email: 'Adres e-mail',
    password: 'Hasło',
    orderId: 'Numer zamówienia',
    birthDate: 'Data urodzenia',
    search: 'Wyszukiwanie',
    save: 'Zapisz',
    comments: 'Uwagi',
    placeholderBirthDate: 'Podaj swoją datę urodzenia',
    birthDateFormat: 'Wpisz datę oddzieloną myślnikami, np.: 1999-12-08',
    select: {
      pickerPlaceholder: 'Wybierz...',
    },
  },
  header: {
    orderId: 'Zamówienie nr',
  },
  bloodSampleRegistration: {
    clinic: 'Punkt pobrania',
    employee: 'Pobierający',
    sampleDate: 'Data pobrania',
    sampleTime: 'Godzina pobrania',
    amountOfSamples: 'Ilośc pobranych próbek',
    type: 'Typ pobranych próbek',
    anatomicalArea: 'Próbka pobrana z',
    comments: 'Komentarz oraz zamówienia dodatkowe',
  },
  other: 'Inne',
  anatomicalArea: {
    label: 'Pobrana z',
    anatomicalAreaOptions: {
      fingerPhalanx: 'Palec',
      internalFlexionOfElbow: 'Łuk łokciowy',
    },
  },
  sampleType: {
    label: 'Krew',
    sampleTypeOptions: {
      capillaryBlood: 'Krew kapilarna',
      venousBlood: 'Krew żylna',
      nasalSwab: '',
      bloodSerum: '',
      dryBloodSample: '',
    },
  },
  bloodRegistration: {
    pickerPlaceholder: 'Wybierz klinikę i oddział',
  },
  gender: {
    label: 'Płeć',
    male: 'Mężczyzna',
    female: 'Kobieta',
  },
  product: {
    label: 'Produkt',
    foodId: 'FoodId',
    allergyChipPro: 'Allergy Chip Doctor Pro',
    allergyChip: 'Allergy Chip',
  },
  orderCreation: {
    clinic: 'Punkt pobrania',
    patientName: 'Imię pacjenta',
    patientLastName: 'Nazwisko pacjenta',
    birthDate: 'Data urodzenia',
    age: 'Wiek',
    product: 'Produkt',
    gender: 'Płeć',
  },
  appUrl: 'https://partners.allergomedica.pl',
};
