import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import CommonAnimatedLoader from '../common/animated/loader';
import CommonButtonDefault from '../common/buttons/default';
import StyledError from '../common/form/styled-error';
import StyledLabel from '../common/form/styled-label';
import StyledInput from '../common/form/styled-input';
import { strings } from '../../localization/strings';
import AlertPrimary from '../common/alerts/alert-primary';
import { SearchOrderForm } from '../../entities/searchOrderForm';

type Inputs = {
  common: string;
  orderId: number;
  // birthDate: string;
  email: string;
};

interface ComponentProps {
  onCompleteSearch: (data: SearchOrderForm) => void;
  orderId?: number;
  email?: string;
}

function SearchOrderFormComponent({ onCompleteSearch }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      await onCompleteSearch(data);
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError('common', { message: e.message });
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}

      {errors.common && (
        <div className="mb-4 px-3">
          <AlertPrimary text={strings().alert.danger.title} type="danger" />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2">
          <StyledLabel htmlFor="orderId">{strings().form.orderId}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.orderId && `!border-red-500`} `}
            {...register('orderId', { required: true })}
          />
          {errors.orderId && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
        </div>
        <div className="py-2">
          <StyledLabel htmlFor="email">{strings().form.email}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.email && `!border-red-500`} `}
            {...register('email', { required: true })}
          />
          {errors.email && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
        </div>

        {/* <div className="py-2"> */}
        {/*   <StyledLabel htmlFor="birthDate">{strings().form.birthDate}*</StyledLabel> */}

        {/*   <div className="mt-1"> */}
        {/*     <Controller */}
        {/*       control={control} */}
        {/*       name="birthDate" */}
        {/*       rules={{ required: true }} */}
        {/*       render={({ field }) => ( */}
        {/*         <DatePicker */}
        {/*           selected={field.value ? new Date(field.value) : undefined} */}
        {/*           onChange={(e) => { */}
        {/*             field.onChange(e); */}
        {/*           }} */}
        {/*           peekNextMonth */}
        {/*           showMonthDropdown */}
        {/*           showYearDropdown */}
        {/*           dropdownMode="select" */}
        {/*           maxDate={today} */}
        {/*           locale={locale === 'pl' ? pl : lt} */}
        {/*           dateFormat="yyyy-MM-dd" */}
        {/*           className={classNames( */}
        {/*             'block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm', */}
        {/*             { */}
        {/*               'border-red-500': errors.birthDate, */}
        {/*             }, */}
        {/*           )} */}
        {/*         /> */}
        {/*       )} */}
        {/*     /> */}

        {/*     {errors.birthDate && <StyledError>{strings().error.fieldIsRequired}</StyledError>} */}
        {/*     <div className="mt-2 text-sm text-content-600">*{strings().form.birthDateFormat}</div> */}
        {/*   </div> */}
        {/* </div> */}
        <div className="flex space-x-4 mt-6">
          <CommonButtonDefault onClick={() => clearErrors('common')} type="submit" primary>
            {strings().form.search}
          </CommonButtonDefault>
          <Link className="ml-auto" to="/">
            <CommonButtonDefault>{strings().button.back}</CommonButtonDefault>
          </Link>
        </div>
      </form>
    </div>
  );
}

SearchOrderFormComponent.defaultProps = {
  orderId: null,
  email: null,
};

export default SearchOrderFormComponent;
