import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { strings } from '../../localization/strings';
import CommonButtonDefault from '../../components/common/buttons/default';

function IndexPageComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      <main>
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
          <div className="lg:w-1/2">{isLoading && <CommonAnimatedLoader />}</div>
          <nav className="w-full border-b border-gray-200 bg-white mb-6 px-3" aria-label="Breadcrumb">
            <ol className="max-w-7xl mx-auto sm:px-6 lg:px-8 flex py-5 space-x-4 justify-start">
              <li>
                <Link className="ml-auto" to="/orders/search">
                  <CommonButtonDefault primary>{strings().button.searchOrder}</CommonButtonDefault>
                </Link>
              </li>
              <li>
                <Link className="ml-auto" to="/orders/create">
                  <CommonButtonDefault primary>{strings().button.createOrder}</CommonButtonDefault>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </main>
    </div>
  );
}

export default IndexPageComponent;
