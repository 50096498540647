export const strings = {
  error: {
    fieldIsRequired: 'Obligātais lauks',
  },
  alert: {
    success: {
      title: 'Veiksmīgi saglabāts',
    },
    danger: {
      title: 'Nevar atrast jūsu pasūtījumu',
    },
    warning: {
      title: 'Dati vēl nav saglabāti.',
      text: 'Apstipriniet ievadītos datus.',
    },
  },
  button: {
    login: 'Pieteikšanās',
    close: 'Aizvērt',
    edit: 'Mainīt',
    delete: 'Dzēst',
    next: 'Turpināt',
    confirm: 'Apstiprināt',
    back: 'Atgriezties',
    createOrder: 'Jauns pasūtījums',
    searchOrder: 'Rezervācijas meklēšana',
  },
  navigation: {
    bloodSampleRegistration: 'Asins parauga reģistrācija',
    phone: '+371 26 538 428',
    haveQuestions: 'Kādi jautājumi?',
  },
  breadcrumbs: {
    searchPatient: 'Pacientu meklēšana',
    registerSample: 'Reģistrācijas paraugs',
    preview: 'Skatīt vietnē',
  },
  form: {
    email: 'E-pasta adrese',
    password: 'Parole',
    orderId: 'Pasūtījuma numurs',
    birthDate: 'Dzimšanas datums',
    search: 'Meklēšana',
    save: 'Saglabāt',
    comments: 'Piezīmes',
    placeholderBirthDate: 'Ievadiet savu dzimšanas datumu',
    birthDateFormat: 'datuma atdalīšanai izmantojiet defisi, piemēram, 1999-12-08.',
    select: {
      pickerPlaceholder: 'Izvēlieties',
    },
  },
  header: {
    orderId: 'Pasūtījuma numurs',
  },
  bloodSampleRegistration: {
    clinic: 'Analīžu ņemšanas vieta',
    employee: 'Analīze',
    sampleDate: 'Analīžu ņemšanas datums',
    sampleTime: 'Analīžu ņemšanas laiks',
    amountOfSamples: 'Analīžu skaits',
    type: 'Analīžu veids',
    anatomicalArea: 'Anatomiskā atrašanās vieta',
    comments: 'Piezīmes',
  },
  other: 'citi',
  anatomicalArea: {
    label: 'Anatomiskā atrašanās vieta',
    anatomicalAreaOptions: {
      fingerPhalanx: 'Pirksta falanga',
      internalFlexionOfElbow: 'Iekšējā elkoņa virsma',
    },
  },
  sampleType: {
    label: 'Analīžu veids',
    sampleTypeOptions: {
      capillaryBlood: 'Kapilārās asinis',
      venousBlood: 'Venozās asinis',
      nasalSwab: 'Deguna uztriepes',
      bloodSerum: 'Asins serums',
      dryBloodSample: 'Sausais asins paraugs',
    },
  },
  bloodRegistration: {
    pickerPlaceholder: 'Izvēlieties savu klīniku un nodaļu',
  },
  gender: {
    label: 'Dzimums',
    male: 'Vīrietis',
    female: 'Sieviete',
  },
  product: {
    label: 'Produkts',
    foodId: '',
    allergyChipPro: 'Allergy Chip Doctor Pro',
    allergyChip: 'Allergy Chip',
  },
  orderCreation: {
    clinic: 'Analīzes ņemšanas vieta',
    patientName: 'Pacienta vārds',
    patientLastName: 'Pacienta uzvārds',
    birthDate: 'Dzimšanas datums',
    age: 'Vecums',
    product: 'Produktas',
    gender: 'Dzimums',
  },
  appUrl: 'https://partners.allergomedica.lv',
};
